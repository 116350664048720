import { Box, Button, Dialog, DialogContent, useTheme } from '@mui/material';
import Rive, { Alignment, Fit, Layout } from '@rive-app/react-canvas';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import riveFile from 'assets/rive/bg-overlay.riv';

import { useAnalytics } from 'hooks';
import { getModal } from 'store/navigation/navigation.selectors';
import { navigationActions } from 'store/navigation/navigation.slice';
import { PageView } from 'types/analytics.types';

import { IcoClose } from 'components/@icons';

import { GameRulesContent } from '../GameRulesContent';
import { GamesContent } from '../GamesContent';
import { GoldenXIntroContent } from '../GoldenXIntroContent';
import { LeaderboardContent } from '../LeaderboardContent';
import { LoginErrorContent } from '../LoginErrorContent';
import { PoiDetailContent } from '../PoiDetailContent';
import { QuizContent } from '../QuizContent';
import { ScoreBreakdownContent } from '../ScoreBreakdownContent';
import { SelectPartnerContent } from '../SelectPartnerContent';
import { VideoContent } from '../VideoContent';

interface Props {
  children?: ReactNode;
}

const fullHeightModals = [
  'quiz',
  'game-rules',
  'select-partner',
  'score-breakdown',
  'leaderboard',
];

const ModalWrapper = ({ children }: Props) => {
  const theme = useTheme();
  const { trackPageView } = useAnalytics();
  const dispatch = useDispatch();
  const visibleModal = useSelector(getModal);
  const modalOpen = !!visibleModal;

  const handleClose = () => {
    dispatch(navigationActions.MODAL_CLOSE());
  };

  const isFullHeight = fullHeightModals.includes(visibleModal?.key ?? '');

  useEffect(() => {
    if (visibleModal?.key === 'games') {
      trackPageView({ path: PageView.MiniGames });
    } else if (visibleModal?.key === 'quiz') {
      trackPageView({ path: PageView.Quiz });
    } else if (visibleModal?.key === 'poi') {
      trackPageView({ path: PageView.Poi });
    } else if (visibleModal?.key === 'select-partner') {
      trackPageView({ path: PageView.Partner });
    } else if (visibleModal?.key === 'game-rules') {
      trackPageView({ path: PageView.Rules });
    } else if (visibleModal?.key === 'leaderboard') {
      trackPageView({ path: PageView.Leaderboard });
    } else if (visibleModal?.key === 'golden-x-intro') {
      trackPageView({ path: PageView.GoldenXIntro });
    } else if (visibleModal?.key === 'video') {
      trackPageView({ path: PageView.VideoDetail });
    }
  }, [visibleModal?.key, trackPageView]);

  const showRive = visibleModal?.key !== 'golden-x-intro';
  const isGoldenModal =
    visibleModal?.key === 'golden-x-intro' ||
    (visibleModal?.key === 'leaderboard' &&
      visibleModal.params.type === 'golden_x') ||
    (visibleModal?.key === 'score-breakdown' &&
      visibleModal.params.type === 'golden_x');

  const hasGoldenBg = isGoldenModal && visibleModal?.key !== 'golden-x-intro';

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            height: {
              xs: isFullHeight ? '100%' : undefined,
              md: isFullHeight ? '90%' : undefined,
            },
            bgcolor: showRive ? undefined : theme.palette.neutral01[100],
          },
        }}
        open={modalOpen}
        onClose={handleClose}
      >
        <>
          {visibleModal && (
            <>
              {showRive && modalOpen && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  zIndex={0}
                  overflow="hidden"
                >
                  <Box
                    position="relative"
                    sx={{ aspectRatio: '1.61' }}
                    width="100%"
                  >
                    <Rive
                      src={riveFile}
                      artboard="Main"
                      layout={
                        new Layout({
                          fit: Fit.Cover,
                          alignment: Alignment.TopLeft,
                        })
                      }
                    />
                    <Box
                      position="absolute"
                      top={0}
                      left={0}
                      right={0}
                      bottom={0}
                      sx={{
                        background:
                          'linear-gradient(0deg, rgba(0, 0, 0, 0) 59.22%, rgba(0, 0, 0, 0.6) 100%)',
                      }}
                    />
                  </Box>
                </Box>
              )}

              {hasGoldenBg && modalOpen && (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  sx={{
                    background: theme.palette.gradientBackgrounds.yellow,
                    opacity: 0.6,
                  }}
                  zIndex={0}
                />
              )}

              <DialogContent
                sx={
                  isGoldenModal
                    ? {
                        backgroundColor: 'transparent',
                        border: `2px solid ${theme.palette.brand01[100]}`,
                        boxShadow: '0px 0px 12px 0px rgba(235, 212, 87, 0.5)',
                      }
                    : undefined
                }
              >
                {visibleModal.key === 'poi' && (
                  <PoiDetailContent poi={visibleModal.params.poi} />
                )}
                {visibleModal.key === 'login-error' && (
                  <LoginErrorContent code={visibleModal.params.code} />
                )}
                {visibleModal.key === 'games' && <GamesContent />}
                {visibleModal.key === 'quiz' && <QuizContent />}
                {visibleModal.key === 'game-rules' && <GameRulesContent />}
                {visibleModal.key === 'select-partner' && (
                  <SelectPartnerContent withPin={visibleModal.params.withPin} />
                )}
                {visibleModal.key === 'score-breakdown' && (
                  <ScoreBreakdownContent type={visibleModal.params.type} />
                )}
                {visibleModal.key === 'leaderboard' && (
                  <LeaderboardContent
                    type={
                      visibleModal.params.type === 'golden_x'
                        ? 'golden'
                        : 'episode'
                    }
                  />
                )}
                {visibleModal.key === 'golden-x-intro' && (
                  <GoldenXIntroContent />
                )}
                {visibleModal.key === 'video' && (
                  <VideoContent
                    url={visibleModal.params.url}
                    title={visibleModal.params.title}
                    autoPlay={visibleModal.params.autoPlay}
                  />
                )}
              </DialogContent>
            </>
          )}
          {modalOpen && (
            <Button
              variant="icon"
              color="secondary"
              sx={{
                position: 'absolute',
                right: { xs: 5, md: -15 },
                top: { xs: -8, md: -15 },
                zIndex: 4444,
              }}
              onClick={handleClose}
            >
              <IcoClose fontSize={20} />
            </Button>
          )}
        </>
      </Dialog>
      {children}
    </>
  );
};

export default ModalWrapper;
