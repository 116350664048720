import { useQuery } from '@tanstack/react-query';

import { QueryKeys, useEpisodes } from 'queries';
import { StaleTime } from 'queries/constants';
import { apiService } from 'services';
import { LeaderboardType } from 'types/leaderboard.types';

const getLeaderboard = async (type: LeaderboardType, reveal?: string) => {
  const { data: response } = await apiService.getLeaderboard(type, reveal);
  return response;
};

export const useLeaderboard = (type: LeaderboardType) => {
  const { currentEpisode } = useEpisodes();

  const { data, status, isLoading, error, isFetching } = useQuery({
    queryKey: QueryKeys.leaderboard.general(type),
    queryFn: () =>
      getLeaderboard(
        type,
        // We pass the episode id when it's revealed. So we know 100% sure the call isn't cached
        currentEpisode?.isRevealed ? currentEpisode.id : undefined,
      ),
    gcTime: StaleTime.FOREVER,
    staleTime: StaleTime.MINUTE,
    meta: { persist: true },
  });

  return {
    data,
    status,
    isLoading,
    isFetching,
    error,
  };
};
