/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */

import config from 'config/env';

import { isDev, isStag } from 'utils/env.utils';

import heatmapEditor from './editor/heatmap';
import heatmapLiveEditor from './editor/heatmap/DFfsij487cssfeC';
import goldenGuessGame from './golden-guess-game';
import guessGame from './guess-game';
import home from './home';
import placeholder from './placeholder';
import { Route } from './routes.types';

const routes = config.GAME_ACTIVE
  ? [home, guessGame, goldenGuessGame]
  : [placeholder];

if (isStag() || isDev()) {
  routes.push(heatmapEditor);
  routes.push(heatmapLiveEditor);
}

export default routes as Array<Route>;
