import { useQuery } from '@tanstack/react-query';

import { useAuth } from 'hooks';
import { CacheTime, StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useFeatureFlags } from 'services/feature-flags';
import { Feature } from 'types/features.types';

const getGoldenXGuesses = async (userId: string) => {
  const { data } = await apiService.getGoldenXGuesses(userId);

  return data;
};

export const useGoldenXGuesses = (enabled = true) => {
  const { hasFeature } = useFeatureFlags();
  const { userId } = useAuth();

  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.goldenX.all(),
    queryFn: () => getGoldenXGuesses(userId ?? ''),
    enabled: !!userId?.length && enabled && hasFeature(Feature.GoldenX),
    gcTime: CacheTime.FOREVER,
    staleTime: StaleTime.TEN_SECONDS,
    meta: { persist: true },
  });

  return {
    data: data ?? [],
    isLoading,
  };
};
