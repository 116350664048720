import { PostGuessGameAnswerParams } from 'types/game.types';
import {
  FinalGoldenX,
  GoldenScoreBreakDown,
  GoldenXPin,
  GoldenXPostGuessGameAnswerResponse,
  GoldenXTip,
} from 'types/golden-x.types';
import { PinPlacement } from 'types/user.types';

import RestApiService from './';

export default {
  postGuessGameAnswer(
    this: RestApiService,
    { userId, episodeId, coordinateId, coordinates }: PostGuessGameAnswerParams,
  ) {
    return this.api.post<GoldenXPostGuessGameAnswerResponse>(
      `/users/${userId}/episodes/${episodeId}/golden-x/guess-game/coordinates/${coordinateId}/answer`,
      coordinates,
      { authEnabled: true },
    );
  },
  getFinalGoldenX(this: RestApiService) {
    return this.api.get<FinalGoldenX>('/golden-x');
  },
  getGoldenXGuesses(this: RestApiService, userId: string) {
    return this.api.get<Array<GoldenXPin>>(`/users/${userId}/golden-x/pins`, {
      authEnabled: true,
    });
  },
  getGoldenXTips(this: RestApiService, userId: string, episodeId: string) {
    return this.api.get<GoldenXTip>(
      `/users/${userId}/episodes/${episodeId}/golden-x/tips`,
      {
        authEnabled: true,
      },
    );
  },
  postUserGoldenPin(
    this: RestApiService,
    userId: string,
    episodeId: string,
    position: { latitude: number; longitude: number },
  ) {
    return this.api.post<PinPlacement>(
      `/users/${userId}/episodes/${episodeId}/golden-x/pin`,
      position,
      { authEnabled: true },
    );
  },
  getGoldenScoreBreakdown(
    this: RestApiService,
    userId: string,
    reveal?: string,
  ) {
    return this.api.get<GoldenScoreBreakDown>(
      `/users/${userId}/golden-x/score-breakdown`,
      {
        authEnabled: true,
        params: { reveal },
      },
    );
  },
};
