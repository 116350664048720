import config from 'config';

export function googleMapsAvailable() {
  return (
    window.google && // Ensure `google` is defined globally
    google?.maps && // Check for the `maps` property
    google?.maps?.LatLngBounds // Ensure `LatLngBounds` is available
  );
}

export function getBoundsZoomLevel(
  bounds: google.maps.LatLngBounds,
  mapDim: { width: number; height: number },
) {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

export const loadGpx = async (
  gpxUrl: string,
): Promise<Array<google.maps.LatLngLiteral>> => {
  try {
    const gpxParser = (await import('gpxparser')).default;

    const data = await fetch(gpxUrl);
    const text = await data.text();
    const gpx = new gpxParser();
    gpx.parse(text);
    if (gpx.tracks.length > 0) {
      return gpx.tracks[0].points.map((v) => ({ lat: v.lat, lng: v.lon }));
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getCenterPosition = (
  start: google.maps.LatLngLiteral,
  end: google.maps.LatLngLiteral,
  projection?: google.maps.Projection,
) => {
  if (!projection) return;

  const startPoint = projection.fromLatLngToPoint(start);
  const endPoint = projection.fromLatLngToPoint(end);

  if (startPoint && endPoint) {
    const midPoint = new google.maps.Point(
      (startPoint.x + endPoint.x) / 2,
      (startPoint.y + endPoint.y) / 2,
    );
    const midLatLng = projection.fromPointToLatLng(midPoint);
    if (midLatLng) {
      return { lat: midLatLng.lat(), lng: midLatLng.lng() };
    }
  }
};

export const getHeading = (
  start: google.maps.LatLngLiteral,
  end: google.maps.LatLngLiteral,
  projection?: google.maps.Projection,
) => {
  if (!projection) return;

  const startPoint = projection.fromLatLngToPoint(start);
  const endPoint = projection.fromLatLngToPoint(end);

  if (startPoint && endPoint) {
    return (
      (Math.atan2(startPoint.y - endPoint.y, startPoint.x - endPoint.x) * 180) /
      Math.PI
    );
  }
};

export const pointIsInsideCircle = (
  point: google.maps.LatLngLiteral,
  circleCenter: google.maps.LatLngLiteral,
  circleRadiusKm: number,
) => {
  if (typeof google === 'undefined') return undefined;

  const distance = google.maps.geometry.spherical.computeDistanceBetween(
    point,
    circleCenter,
  );
  const isInside = distance <= circleRadiusKm * 1000;
  return isInside;
};

export const formatDistance = (meters: number) => {
  const casing = config.DISTANCE_CASING;
  const formattedKm = casing === 'upper' ? 'KM' : 'km';
  const formattedM = casing === 'upper' ? 'M' : 'm';

  if (meters > 1000) {
    const km = meters / 1000;

    const fixed = km.toFixed(km >= 100 ? 0 : 1);
    return `${fixed.endsWith('.0') ? km.toFixed(0) : fixed} ${formattedKm}`;
  }

  return `${meters.toFixed(1)} ${formattedM}`;
};

export const getDistance = (
  start: google.maps.LatLngLiteral,
  end: google.maps.LatLngLiteral,
) => {
  const meters = google.maps.geometry.spherical.computeDistanceBetween(
    start,
    end,
  );

  return { meters, formatted: formatDistance(meters) };
};

export const getPixelDistance = (
  start: google.maps.LatLngLiteral,
  end: google.maps.LatLngLiteral,
  zoom: number,
  projection?: google.maps.Projection,
) => {
  if (!projection) return;

  const startPoint = projection.fromLatLngToPoint(start);
  const endPoint = projection.fromLatLngToPoint(end);

  if (startPoint && endPoint) {
    const startScreen = getScreenSpace(startPoint, zoom);
    const endScreen = getScreenSpace(endPoint, zoom);

    return Math.hypot(endScreen.x - startScreen.x, endScreen.y - startScreen.y);
  }
};

export const getScreenSpace = (point: google.maps.Point, zoom: number) => ({
  x: point.x * Math.pow(2, zoom),
  y: point.y * Math.pow(2, zoom),
});
