import { useQuery } from '@tanstack/react-query';
import _isEqual from 'lodash/isEqual';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from 'config';

import { QueryKeys } from 'queries';
import { useLogout } from 'queries/auth/useLogout';
import { CacheTime, StaleTime } from 'queries/constants';
import { apiService } from 'services';
import { getRedirectRoute } from 'store/auth/auth.selectors';
import { authActions } from 'store/auth/auth.slice';
import { navigationActions } from 'store/navigation/navigation.slice';

const getUser = async () => {
  const { data: response } = await apiService.getUser();
  return response;
};

const IS_COOKIE_AUTH = config.AUTH_TYPE === 'cookie';

export const useCookieAuth = () => {
  const redirectRoute = useSelector(getRedirectRoute);
  const posthog = usePostHog();
  const { logout } = useLogout();
  const dispatch = useDispatch();

  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.user.me(),
    queryFn: async () => {
      try {
        const user = await getUser();

        if (typeof user !== 'object') {
          throw new Error('Invalid data');
        }

        dispatch(authActions.LOGIN_SUCCESS());

        posthog.identify(user.id);

        if (redirectRoute) {
          dispatch(navigationActions.CONTENT_NAVIGATE_TO(redirectRoute));
        }

        return user;
      } catch (e) {
        return 'no-user';
      }
    },
    enabled: IS_COOKIE_AUTH,
    retry: false,
    refetchOnWindowFocus: true,
    staleTime: StaleTime.FOREVER,
    gcTime: CacheTime.FOREVER,
    meta: { persist: true },
  });

  useEffect(() => {
    if (data === 'no-user' && IS_COOKIE_AUTH) {
      logout();
    }
  }, [data, logout]);

  const userData = !!data && data !== 'no-user' ? data : undefined;
  const needsFirstName = !!userData && !userData?.firstName?.length;
  const isLoggedIn = !!userData && !needsFirstName && !!userData?.id;

  useEffect(() => {
    if (needsFirstName && !isLoggedIn) {
      dispatch(
        navigationActions.MODAL_OPEN({
          key: 'login-error',
          params: { code: 'missing_first_name' },
        }),
      );
    }
  }, [dispatch, isLoggedIn, needsFirstName]);

  return { isLoggedIn, isLoading, userId: userData?.id };
};
