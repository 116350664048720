import {
  Box,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { motion, useAnimationControls } from 'motion/react';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Participant } from 'types/episode.types';

import { IcoStar } from 'components/@icons/custom';

import { FadeImage } from '../FadeImage';

interface AvatarProps {
  name: string;
  position: number;
  imageUrl: string;
  onClick?: () => void;
  selected?: boolean;
  isSide?: boolean;
  isLoser?: boolean;
  isPartner?: boolean;
}

const MotionWrap = styled(motion.div)(() => ({
  position: 'relative',
  '&:not(:last-child)': {
    marginRight: -6,
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const MotionAvatarWrap = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  borderRadius: '50%',
  width: 44,
  height: 44,
  border: `2px solid ${theme.palette.neutral01[100]}`,
}));

const Avatar = ({
  imageUrl,
  // position,
  name,
  onClick,
  selected,
  isLoser,
  isSide,
  isPartner,
}: AvatarProps) => {
  const controls = useAnimationControls();

  const start = useCallback(() => {
    controls.start('hover');
  }, [controls]);

  const end = useCallback(() => {
    controls.start('default');
  }, [controls]);

  useEffect(() => {
    if (selected) {
      start();
    } else {
      end();
    }
  }, [end, selected, start]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MotionWrap
      initial="default"
      animate={controls}
      onHoverEnd={isSmall ? undefined : end}
      onHoverStart={isSmall ? undefined : start}
      onTap={onClick}
      variants={{
        hover: { width: 66, zIndex: 5 },
        default: {
          zIndex: 1,
          width: isSmall ? 66 : 44,
          transition: {
            delay: isSide ? 0 : 0.2,
            duration: 0.2,
          },
        },
      }}
    >
      <MotionAvatarWrap
        variants={{
          hover: {
            borderColor: isLoser ? '#B90000' : theme.palette.brand01[100],
            boxShadow: isLoser
              ? '0px 1px 8px rgba(242, 22, 0, 0.3)'
              : '0px 0px 8px rgba(235, 212, 87, 0.5)',
            width: 66,
            height: 66,
            transition: {
              delay: isSmall || isSide ? 0 : 0.1,
              type: 'spring',
              stiffness: 200,
              damping: 30,
            },
          },
          default: {
            borderColor: isLoser
              ? '#B90000'
              : isPartner
                ? theme.palette.brand01[100]
                : theme.palette.neutral01[100],
            boxShadow: '0px 0px 8px rgba(235, 212, 87, 0)',
            width: isSmall ? 66 : 44,
            height: isSmall ? 66 : 44,
            transition: {
              delay: isSide ? 0 : 0.1,
              duration: 0.2,
            },
          },
        }}
      >
        <FadeImage
          width="100%"
          height="100%"
          src={imageUrl}
          sx={{
            borderRadius: '50%',
            filter: isLoser ? 'grayscale(1)' : undefined,
          }}
        />
        {isPartner && (
          <Box
            position="absolute"
            top={-8}
            right={-2}
            color={isLoser ? '#B90000' : theme.palette.brand01[100]}
          >
            <IcoStar width={16} />
          </Box>
        )}
      </MotionAvatarWrap>
      <motion.div
        variants={{
          hover: { opacity: 1, transition: { delay: 0.15 } },
          default: { opacity: 0, transition: { duration: 0.1 } },
        }}
        style={{ pointerEvents: 'none' }}
      >
        <Stack alignItems="center" mt={0.5}>
          <Typography variant="cta2">{name}</Typography>
          <Typography variant="caption1" width="max-content">
            {isLoser ? (
              <FormattedMessage id="detail.summary.participant.loser.label" />
            ) : (
              <>
                {/* For now we hade the order of the players <FormattedMessage id="detail.summary.participant.position.label" /> */}
                &nbsp;
                {/* {position} */}
              </>
            )}
          </Typography>
        </Stack>
      </motion.div>
    </MotionWrap>
  );
};

interface Props {
  participants: Array<Participant>;
  losers?: number;
  partnerId?: string;
}

const AvatarList = ({ participants, partnerId, losers = 1 }: Props) => {
  const [dragWidth, setDragWidth] = useState(0);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const itemWidth = 60; // Width of each avatar item
  const marginOverlap = 6; // Extra margin to prevent overlap
  const totalWidth = participants.length * itemWidth + marginOverlap;

  const leftConstraint = isSmall ? dragWidth - totalWidth - marginOverlap : 0;
  const canDrag = totalWidth > dragWidth;
  const [selectedId, setSelectedId] = useState<string>();

  const onRefLoaded = useCallback(async (node: HTMLDivElement) => {
    if (node) {
      setDragWidth(node.clientWidth);
    }
  }, []);

  return (
    <Box ref={onRefLoaded}>
      <motion.div
        drag={canDrag && isSmall ? 'x' : false}
        dragConstraints={{
          left: leftConstraint,
          right: 0,
        }}
      >
        <Stack direction="row" flexShrink={0} alignItems="center" height={112}>
          {participants.map((p, i) => (
            <Avatar
              key={p.id}
              name={p.firstName}
              imageUrl={p.imageUrl}
              position={i + 1}
              selected={selectedId === p.id}
              onClick={isSmall ? () => setSelectedId(p.id) : undefined}
              isSide={i === 0 || i === participants.length - 1}
              isLoser={i >= participants.length - losers}
              isPartner={partnerId === p.id}
            />
          ))}
        </Stack>
      </motion.div>
    </Box>
  );
};

export default AvatarList;
