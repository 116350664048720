import {
  Box,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { motion, Transition, Variants } from 'motion/react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useGoldenScoreBreakDown, useScoreBreakDown } from 'queries';
import { navigationActions } from 'store/navigation/navigation.slice';
import { BreakDownItem, BreakdownType } from 'types/breakdown.types';
import { GoldenBreakDownItem } from 'types/golden-x.types';

import { EpisodeBreakdown, GoldenBreakdown, Points } from 'components/@score';
import { ShareScoreButton } from 'components/@share';
import LoadingState from 'components/@states/LoadingState';

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const transition = (index: number): Transition => {
  return { delay: 0.5 + index * 0.3, duration: 0.7, ease: 'easeOut' };
};

interface Props {
  type: BreakdownType;
}

const ScoreBreakdownContent = ({ type }: Props) => {
  const {
    data: episodeData,
    totalScore: episodeTotalScore,
    isLoading: episodeIsLoading,
  } = useScoreBreakDown(type === 'episode');
  const {
    data: goldenData,
    totalScore: goldenTotalScore,
    isLoading: goldenIsLoading,
  } = useGoldenScoreBreakDown(type === 'golden_x');

  const data = type === 'episode' ? episodeData : goldenData;
  const totalScore = type === 'episode' ? episodeTotalScore : goldenTotalScore;
  const isLoading = type === 'episode' ? episodeIsLoading : goldenIsLoading;

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenGameRules = () => {
    dispatch(navigationActions.MODAL_OPEN({ key: 'game-rules' }));
  };

  return (
    <motion.div initial="initial" animate="animate">
      <Stack flex={1}>
        <motion.div variants={animationVariant} transition={transition(0)}>
          {type === 'golden_x' && (
            <Typography variant={isMobile ? 'h4' : 'h2'} color="brand01.100">
              <FormattedMessage id="golden_x.general.label" />
            </Typography>
          )}
          <Typography variant={isMobile ? 'h4' : 'h2'} mb={5}>
            <FormattedMessage id="score.breakdown.modal.title" />
          </Typography>
        </motion.div>

        {isLoading ? (
          <LoadingState />
        ) : (
          <motion.div variants={animationVariant} transition={transition(1)}>
            <Grid
              container
              wrap="nowrap"
              direction={{ xs: 'column-reverse', md: 'row' }}
              spacing={{ xs: 4, md: 5 }}
            >
              <Grid item xs={12} md={7}>
                <Stack gap={4}>
                  {data?.breakdown.map((breakdown, index) => (
                    <Box key={breakdown.episodeId}>
                      <Typography color="primary.main" variant="paragraph2">
                        <FormattedMessage id="home.card.episode.title" />{' '}
                        {data.breakdown.length - index}
                      </Typography>
                      <Divider sx={{ margin: '10px 0' }} />
                      {type === 'episode' && (
                        <EpisodeBreakdown
                          breakdown={breakdown as BreakDownItem}
                        />
                      )}
                      {type === 'golden_x' && (
                        <GoldenBreakdown
                          breakdown={breakdown as GoldenBreakDownItem}
                        />
                      )}
                    </Box>
                  ))}
                </Stack>
                <Divider sx={{ mt: '10px' }} />
                <Link mt={3} onClick={handleOpenGameRules}>
                  <FormattedMessage id="home.link.game_rules" />
                </Link>
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="column"
                alignItems="center"
                xs={12}
                md={5}
              >
                <Points width={250} score={totalScore} />
                <Box mt={4}>
                  <ShareScoreButton score={totalScore} />
                </Box>
              </Grid>
            </Grid>
          </motion.div>
        )}
      </Stack>
    </motion.div>
  );
};

export default ScoreBreakdownContent;
