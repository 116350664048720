import { memo, SVGProps } from 'react';

const SvgIcoVtmGoColor = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 78 19" fill="none" {...props}>
    <g clipPath="url(#clip0_9458_1637)">
      <path
        d="M42.8543 3.96948C40.9767 3.54471 38.2175 4.17421 37.4747 5.624C37.4644 5.6438 37.4439 5.65685 37.4211 5.65775C37.3965 5.65685 37.3765 5.64785 37.3643 5.62895C36.5908 4.43294 35.3662 3.77734 33.9107 3.77734C33.887 3.77734 33.8628 3.77734 33.8391 3.77779C32.3757 3.79939 30.964 4.49369 30.2775 5.46607L30.236 5.46427C30.3668 4.87796 30.4543 4.19176 30.4785 3.86059H25.4903L25.4727 17.0576H30.1802L30.1988 10.2105C30.2114 8.9614 31.0264 8.26351 31.7874 8.24101C31.7995 8.24056 31.812 8.24056 31.8241 8.24056C32.4232 8.24056 33.2261 8.66758 33.2261 9.90093V17.0576H38.3646V10.2344C38.3772 8.98525 39.1963 8.28735 39.9611 8.26441C39.9732 8.26396 39.9857 8.26396 39.9983 8.26396C40.6001 8.26396 41.4058 8.69277 41.4058 9.92253V17.0581H46.5434V9.34478C46.5434 5.39497 44.232 4.28176 42.8548 3.96993L42.8543 3.96948Z"
        fill="#FF00FF"
      />
      <path
        d="M10.6294 3.85815C10.6294 5.67017 9.89213 9.22535 8.10435 11.4185L5.07383 3.75781L0 4.41341L5.02636 17.0566H10.1407C11.7535 15.3017 13.1349 13.1563 14.0369 11.0347C14.0369 11.0347 15.6865 7.71032 15.6865 3.8595L10.6294 3.85861V3.85815Z"
        fill="#FF00FF"
      />
      <path
        d="M16.2779 10.5177C16.2779 14.4337 18.6605 17.0579 22.5642 17.0579H24.8845V12.5884L23.113 12.5875C21.7674 12.5875 21.0459 11.5292 21.0459 10.0222V8.32452H23.9522C24.0341 8.09503 24.7248 5.98019 24.9431 3.86085L20.8509 3.85995C21.2521 2.85608 21.2614 1.9089 21.2694 1.60742H16.2695L16.2784 10.5172L16.2779 10.5177Z"
        fill="#FF00FF"
      />
      <path
        d="M66.4871 16.5082C65.3379 15.9183 64.4396 15.076 63.7922 13.9817C63.1443 12.8874 62.8203 11.6054 62.8203 10.1349C62.8203 8.66442 63.1443 7.38291 63.7922 6.2886C64.4401 5.19428 65.3379 4.35194 66.4871 3.76204C67.6363 3.17213 68.9437 2.87695 70.4099 2.87695C71.876 2.87695 73.1835 3.17213 74.3327 3.76204C75.4814 4.35194 76.3802 5.19383 77.0281 6.2886C77.676 7.38291 77.9999 8.66487 77.9999 10.1349C77.9999 11.6049 77.676 12.8874 77.0281 13.9817C76.3802 15.0755 75.4819 15.9183 74.3327 16.5082C73.1835 17.0981 71.876 17.3929 70.4099 17.3929C68.9437 17.3929 67.6363 17.0981 66.4871 16.5082ZM68.078 12.8986C68.637 13.5479 69.4143 13.8728 70.4099 13.8728C71.4055 13.8728 72.1828 13.5484 72.7422 12.8986C73.3012 12.2493 73.581 11.3282 73.581 10.1349C73.581 8.9416 73.3012 8.02052 72.7422 7.37122C72.1828 6.72191 71.4055 6.39704 70.4099 6.39704C69.4143 6.39704 68.637 6.72146 68.078 7.37122C67.5185 8.02052 67.2393 8.9416 67.2393 10.1349C67.2393 11.3282 67.5185 12.2497 68.078 12.8986Z"
        fill="#FF00FF"
      />
      <path
        d="M56.0206 9.70084C55.9214 10.6539 55.7134 11.651 55.3685 12.6279H57.4109C57.2847 12.8952 57.1097 13.1381 56.8723 13.3496C56.48 13.6992 55.8888 13.8738 55.0976 13.8738C54.5335 13.8738 54.0443 13.7712 53.6244 13.5737C53.5355 13.5283 53.4457 13.4846 53.3633 13.4297C52.8788 13.1066 52.5158 12.6616 52.2742 12.0947C52.0322 11.5282 51.9111 10.8753 51.9111 10.1369C51.9111 8.9638 52.1858 8.04722 52.7345 7.38802C53.2833 6.72882 54.0727 6.39899 55.1022 6.39899C55.893 6.39899 56.4846 6.57358 56.877 6.9232C57.2689 7.27283 57.5095 7.69759 57.598 8.19886H61.8731C61.8186 7.17068 61.5114 6.25455 60.9524 5.45001C60.393 4.64592 59.6138 4.01642 58.6154 3.5615C57.6166 3.10659 56.4455 2.87891 55.1022 2.87891C53.5816 2.87891 52.2467 3.17543 51.0975 3.76894C49.9483 4.36244 49.0603 5.20613 48.4329 6.30045C47.8054 7.39477 47.4922 8.67357 47.4922 10.1369C47.4922 11.6002 47.804 12.9105 48.4282 14.0084C49.0524 15.1058 49.9046 15.945 50.9854 16.525C52.0661 17.1046 53.2749 17.3948 54.6112 17.3948C55.5114 17.3948 56.2989 17.2319 56.9738 16.9053C57.6487 16.579 58.1909 16.116 58.6001 15.5158C58.6769 15.4064 58.7904 15.2003 58.9273 14.9344V17.0582H62.016V9.70129H56.0196L56.0206 9.70084Z"
        fill="#FF00FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_9458_1637">
        <rect width="78" height="19" fill="#FF00FF" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoVtmGoColor);
export default Memo;

<svg
  width="78"
  height="19"
  viewBox="0 0 78 19"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clipPath="url(#clip0_9458_1637)">
    <path
      d="M42.8543 3.96948C40.9767 3.54471 38.2175 4.17421 37.4747 5.624C37.4644 5.6438 37.4439 5.65685 37.4211 5.65775C37.3965 5.65685 37.3765 5.64785 37.3643 5.62895C36.5908 4.43294 35.3662 3.77734 33.9107 3.77734C33.887 3.77734 33.8628 3.77734 33.8391 3.77779C32.3757 3.79939 30.964 4.49369 30.2775 5.46607L30.236 5.46427C30.3668 4.87796 30.4543 4.19176 30.4785 3.86059H25.4903L25.4727 17.0576H30.1802L30.1988 10.2105C30.2114 8.9614 31.0264 8.26351 31.7874 8.24101C31.7995 8.24056 31.812 8.24056 31.8241 8.24056C32.4232 8.24056 33.2261 8.66758 33.2261 9.90093V17.0576H38.3646V10.2344C38.3772 8.98525 39.1963 8.28735 39.9611 8.26441C39.9732 8.26396 39.9857 8.26396 39.9983 8.26396C40.6001 8.26396 41.4058 8.69277 41.4058 9.92253V17.0581H46.5434V9.34478C46.5434 5.39497 44.232 4.28176 42.8548 3.96993L42.8543 3.96948Z"
      fill="#FF00FF"
    />
    <path
      d="M10.6294 3.85815C10.6294 5.67017 9.89213 9.22535 8.10435 11.4185L5.07383 3.75781L0 4.41341L5.02636 17.0566H10.1407C11.7535 15.3017 13.1349 13.1563 14.0369 11.0347C14.0369 11.0347 15.6865 7.71032 15.6865 3.8595L10.6294 3.85861V3.85815Z"
      fill="#FF00FF"
    />
    <path
      d="M16.2779 10.5177C16.2779 14.4337 18.6605 17.0579 22.5642 17.0579H24.8845V12.5884L23.113 12.5875C21.7674 12.5875 21.0459 11.5292 21.0459 10.0222V8.32452H23.9522C24.0341 8.09503 24.7248 5.98019 24.9431 3.86085L20.8509 3.85995C21.2521 2.85608 21.2614 1.9089 21.2694 1.60742H16.2695L16.2784 10.5172L16.2779 10.5177Z"
      fill="#FF00FF"
    />
    <path
      d="M66.4871 16.5082C65.3379 15.9183 64.4396 15.076 63.7922 13.9817C63.1443 12.8874 62.8203 11.6054 62.8203 10.1349C62.8203 8.66442 63.1443 7.38291 63.7922 6.2886C64.4401 5.19428 65.3379 4.35194 66.4871 3.76204C67.6363 3.17213 68.9437 2.87695 70.4099 2.87695C71.876 2.87695 73.1835 3.17213 74.3327 3.76204C75.4814 4.35194 76.3802 5.19383 77.0281 6.2886C77.676 7.38291 77.9999 8.66487 77.9999 10.1349C77.9999 11.6049 77.676 12.8874 77.0281 13.9817C76.3802 15.0755 75.4819 15.9183 74.3327 16.5082C73.1835 17.0981 71.876 17.3929 70.4099 17.3929C68.9437 17.3929 67.6363 17.0981 66.4871 16.5082ZM68.078 12.8986C68.637 13.5479 69.4143 13.8728 70.4099 13.8728C71.4055 13.8728 72.1828 13.5484 72.7422 12.8986C73.3012 12.2493 73.581 11.3282 73.581 10.1349C73.581 8.9416 73.3012 8.02052 72.7422 7.37122C72.1828 6.72191 71.4055 6.39704 70.4099 6.39704C69.4143 6.39704 68.637 6.72146 68.078 7.37122C67.5185 8.02052 67.2393 8.9416 67.2393 10.1349C67.2393 11.3282 67.5185 12.2497 68.078 12.8986Z"
      fill="#FF00FF"
    />
    <path
      d="M56.0206 9.70084C55.9214 10.6539 55.7134 11.651 55.3685 12.6279H57.4109C57.2847 12.8952 57.1097 13.1381 56.8723 13.3496C56.48 13.6992 55.8888 13.8738 55.0976 13.8738C54.5335 13.8738 54.0443 13.7712 53.6244 13.5737C53.5355 13.5283 53.4457 13.4846 53.3633 13.4297C52.8788 13.1066 52.5158 12.6616 52.2742 12.0947C52.0322 11.5282 51.9111 10.8753 51.9111 10.1369C51.9111 8.9638 52.1858 8.04722 52.7345 7.38802C53.2833 6.72882 54.0727 6.39899 55.1022 6.39899C55.893 6.39899 56.4846 6.57358 56.877 6.9232C57.2689 7.27283 57.5095 7.69759 57.598 8.19886H61.8731C61.8186 7.17068 61.5114 6.25455 60.9524 5.45001C60.393 4.64592 59.6138 4.01642 58.6154 3.5615C57.6166 3.10659 56.4455 2.87891 55.1022 2.87891C53.5816 2.87891 52.2467 3.17543 51.0975 3.76894C49.9483 4.36244 49.0603 5.20613 48.4329 6.30045C47.8054 7.39477 47.4922 8.67357 47.4922 10.1369C47.4922 11.6002 47.804 12.9105 48.4282 14.0084C49.0524 15.1058 49.9046 15.945 50.9854 16.525C52.0661 17.1046 53.2749 17.3948 54.6112 17.3948C55.5114 17.3948 56.2989 17.2319 56.9738 16.9053C57.6487 16.579 58.1909 16.116 58.6001 15.5158C58.6769 15.4064 58.7904 15.2003 58.9273 14.9344V17.0582H62.016V9.70129H56.0196L56.0206 9.70084Z"
      fill="#FF00FF"
    />
  </g>
  <defs>
    <clipPath id="clip0_9458_1637">
      <rect width="78" height="19" fill="#FF00FF" />
    </clipPath>
  </defs>
</svg>;
