import { Box, Stack, Typography } from '@mui/material';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { formatDistance } from 'utils/map.utils';

import { GoldenBreakDownItem } from 'types/golden-x.types';

interface Props {
  breakdown: GoldenBreakDownItem;
  divider?: boolean;
}

const GoldenBreakdown: React.FC<Props> = ({ breakdown, divider }) => {
  const { userPin } = breakdown;

  return (
    <Stack width="100%" gap={1}>
      <Box
        pb={1}
        borderBottom={divider ? 1 : 0}
        borderColor="neutral01.300"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="paragraph2">
          {userPin ? (
            <>
              {formatDistance(userPin.distanceInMeters)}{' '}
              <Typography
                color="neutral01.500"
                variant="paragraph2"
                component="span"
              >
                <FormattedMessage id="score.breakdown.distance.text" />
              </Typography>{' '}
              <FormattedMessage id="golden_x.general.label" />
            </>
          ) : (
            <FormattedMessage id="score.breakdown.distance.empty.text" />
          )}
        </Typography>
        <Typography variant="h5">
          + {userPin ? <FormattedNumber value={userPin.pointsScored} /> : 0}
        </Typography>
      </Box>
    </Stack>
  );
};

export default GoldenBreakdown;
