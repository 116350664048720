import { useQueryClient } from '@tanstack/react-query';
import {
  differenceInMinutes,
  differenceInSeconds,
  isPast,
  parseISO,
} from 'date-fns';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { isDev, isStag } from 'utils/env.utils';

import { QueryKeys } from 'queries/QueryKeys';
import {
  ContentRoute,
  navigationActions,
} from 'store/navigation/navigation.slice';
import { Episode } from 'types/episode.types';

enum PollingType {
  BeforeEpisode = 'before_episode',
  EpisodeLive = 'episode_live',
  EpisodeReveal = 'episode_reveal',
}

// Polling in seconds
const POLLING_TYPES = [
  { type: PollingType.BeforeEpisode, interval: 60 },
  { type: PollingType.EpisodeLive, interval: 60 * 5 },
  { type: PollingType.EpisodeReveal, interval: 15 },
];

const POLLING_THRESHOLD_BEFORE_EPISODE = 60 * 5;

type Polling = (typeof POLLING_TYPES)[0];

export const usePolling = (episode?: Episode) => {
  const dispatch = useDispatch();
  const completedPollingEpisode = useRef<string>();
  const pollingEpisode = useRef<string>();
  const queryClient = useQueryClient();

  const timeoutRef = useRef<NodeJS.Timer>();
  const lockDatetimeoutRef = useRef<NodeJS.Timer>();

  const handleRefetchScore = () => {
    queryClient.invalidateQueries({ queryKey: QueryKeys.scoreBreakDown() });
    queryClient.invalidateQueries({
      queryKey: QueryKeys.leaderboard.all(),
    });
    queryClient.invalidateQueries({
      queryKey: QueryKeys.pin.all(),
    });

    if (episode?.isRevealed) {
      if (episode.isLastEpisode) {
        queryClient.invalidateQueries({ queryKey: QueryKeys.goldenX.final() });
        queryClient.invalidateQueries({
          queryKey: QueryKeys.goldenX.scoreBreakDown(),
        });
        queryClient.invalidateQueries({
          queryKey: QueryKeys.leaderboard.general('golden_x'),
        });
        queryClient.invalidateQueries({ queryKey: QueryKeys.goldenX.all() });
      }

      const route: ContentRoute = {
        key: 'episode-detail',
        params: { episodeId: episode.id },
      };

      dispatch(navigationActions.CONTENT_NAVIGATE_TO(route));
    } else {
      dispatch(navigationActions.CONTENT_NAVIGATE_HOME());
    }
  };

  const checkPollingType = () => {
    if (!episode) {
      return;
    }

    let polling: Polling | undefined;

    // Check if we are close to the start date to start polling for the live
    if (
      !episode.isLive &&
      differenceInMinutes(parseISO(episode.startDate), new Date()) <=
        POLLING_THRESHOLD_BEFORE_EPISODE / 60
    ) {
      polling = POLLING_TYPES.find((f) => f.type === PollingType.BeforeEpisode);
    } else if (episode.isLive && !episode.isRevealed) {
      // When the votes locked date is in the past we can poll faster
      // because we know the score calculation is coming
      if (
        episode.votesLockedDate &&
        isPast(parseISO(episode.votesLockedDate))
      ) {
        polling = POLLING_TYPES.find(
          (f) => f.type === PollingType.EpisodeReveal,
        );
      } else {
        // Else we just poll a little slower waiting for the reveal
        polling = POLLING_TYPES.find((f) => f.type === PollingType.EpisodeLive);

        // But force a poll at locked date if we have one
        if (episode.votesLockedDate && !lockDatetimeoutRef.current) {
          const secondsTillLockDate =
            differenceInSeconds(parseISO(episode.votesLockedDate), new Date()) +
            5;

          if (isStag() || isDev()) {
            // eslint-disable-next-line no-console
            console.log(
              `Creating polling timer until lock date: ${secondsTillLockDate} - episode: ${episode?.id}`,
            );
          }

          lockDatetimeoutRef.current = setTimeout(() => {
            checkPollingType();
          }, secondsTillLockDate * 1000);
        }
      }
    } else if (
      episode.isRevealed &&
      completedPollingEpisode.current !== pollingEpisode.current
    ) {
      polling = undefined;
      completedPollingEpisode.current = pollingEpisode.current;
      handleRefetchScore();
    } else if (!episode.isLive) {
      // If we are not near the start of the episode we just set a timer that starts polling 5 min before the episode start time
      // Just in case a user keeps the screen open for too long
      const secondsTillNow =
        differenceInSeconds(parseISO(episode.startDate), new Date()) - 60 * 3;

      if (isStag() || isDev()) {
        // eslint-disable-next-line no-console
        console.log(
          `Creating polling timer until start episode: ${secondsTillNow} - episode: ${episode?.id}`,
        );
      }

      // Don't set a timer when too far away (exceeding max time out)
      if (secondsTillNow * 1000 >= 2147483647) {
        if (isStag() || isDev()) {
          // eslint-disable-next-line no-console
          console.log(
            `Don't set timer: ${secondsTillNow} - episode: ${episode?.id} - Exceeding max timeout`,
          );
        }
        return;
      }

      timeoutRef.current = setTimeout(() => {
        if (!pollingEpisode.current) {
          const poll = POLLING_TYPES.find(
            (f) => f.type === PollingType.BeforeEpisode,
          );
          if (poll) {
            timeoutRef.current = createPolling(poll);
          }
        }
      }, secondsTillNow * 1000);
    }

    if (polling) {
      pollingEpisode.current = episode.id;
      timeoutRef.current = createPolling(polling);
    }
  };

  const createPolling = (polling: Polling) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (isStag() || isDev()) {
      // eslint-disable-next-line no-console
      console.log(
        `Creating polling timer: ${polling.type} - ${polling.interval} - episode: ${episode?.id}`,
      );
    }

    return setTimeout(() => {
      if (!completedPollingEpisode.current) {
        queryClient.invalidateQueries({ queryKey: QueryKeys.episode.all() });
        checkPollingType();
      }
    }, polling.interval * 1000);
  };

  if (episode) {
    checkPollingType();
  }
};
