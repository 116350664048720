import { isPast, parseISO } from 'date-fns';
import { useMemo } from 'react';

import { useEpisodes, useFinalGoldenX, useGoldenXGuess } from 'queries';
import { Episode } from 'types/episode.types';
import { GoldenXStatus } from 'types/golden-x.types';

export const useGoldenXStatus = (episodeId?: Episode['id']) => {
  const guess = useGoldenXGuess(episodeId);
  const { episodes } = useEpisodes();
  const { data: finalGoldenX } = useFinalGoldenX();

  const episode = useMemo(() => {
    const foundEpisode = episodes?.find((e) => e.id === episodeId);

    return foundEpisode;
  }, [episodeId, episodes]);

  const info: { status: GoldenXStatus; open: boolean } = useMemo(() => {
    const started =
      !!episode?.goldenXStartDate && isPast(parseISO(episode.goldenXStartDate));
    const ended =
      !!episode?.goldenXEndDate && isPast(parseISO(episode.goldenXEndDate));

    let isOpen = started && !ended;

    if (finalGoldenX?.isRevealed) {
      isOpen = false;
    }

    if (guess && started) {
      return { status: 'done', open: isOpen };
    } else if (finalGoldenX?.isRevealed && started) {
      return { status: 'late', open: isOpen };
    } else if (started && !ended) {
      return { status: 'open', open: isOpen };
    } else if (started && ended) {
      return { status: 'late', open: isOpen };
    } else {
      return { status: 'locked', open: isOpen };
    }
  }, [
    episode?.goldenXEndDate,
    episode?.goldenXStartDate,
    finalGoldenX?.isRevealed,
    guess,
  ]);

  return { ...info, episode };
};
