import { Box, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { motion, Transition, Variants } from 'motion/react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAuth } from 'hooks';
import { useLeaderboard, useUserLeaderboard } from 'queries';
import theme from 'theme';
import { LeaderboardType } from 'types/leaderboard.types';

import { Leaderboard } from 'components/@leaderboard';
import LoadingState from 'components/@states/LoadingState';

const POSITION_SPLIT = 15;

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const transition = (index: number): Transition => {
  return { delay: 0.5 + index * 0.3, duration: 0.7, ease: 'easeOut' };
};

interface LeaderboardContentProps {
  type: 'episode' | 'golden';
}

const LeaderboardContent = ({ type }: LeaderboardContentProps) => {
  const { userId } = useAuth();
  const [activeTab, setActiveTab] = useState(0);

  let leaderboardType: LeaderboardType = 'total';
  if (type === 'golden') {
    leaderboardType = 'golden_x';
  } else if (type === 'episode' && activeTab === 0) {
    leaderboardType = 'last_episode';
  }

  const { data: leaderboard, isFetching: isFetchingLeaderboard } =
    useLeaderboard(leaderboardType);
  const { data: userLeaderboard, isFetching: isFetchingUserLeaderboard } =
    useUserLeaderboard(leaderboardType);

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const userLeaderboardUserIdx =
    userLeaderboard?.findIndex((l) => l.id === userId) ?? -1;
  const currentUser = userLeaderboard?.[userLeaderboardUserIdx];

  const finalLeaderboard = leaderboard?.slice(
    0,
    currentUser &&
      currentUser.position <= POSITION_SPLIT &&
      currentUser.position > 10
      ? POSITION_SPLIT
      : 10,
  );

  const finalUserLeaderboard = userLeaderboard;

  // Add 2 fake users when you're the last one
  if (finalUserLeaderboard) {
    const lastUser = finalUserLeaderboard[finalUserLeaderboard.length - 1];
    if (lastUser.id === userId) {
      finalUserLeaderboard.push({
        id: 'user-1',
        firstName: type === 'episode' ? 'Pieter' : 'Barbara',
        lastNameInitials: 'V',
        createdAt: new Date().toISOString(),
        score: Math.max(lastUser.score - 10, 0),
        position: lastUser.position + 1,
      });
      finalUserLeaderboard.push({
        id: 'user-2',
        firstName: type === 'episode' ? 'James' : 'Dirk',
        lastNameInitials: 'R',
        createdAt: new Date().toISOString(),
        score: Math.max(lastUser.score - 20, 0),
        position: lastUser.position + 2,
      });
    }
  }

  return (
    <motion.div
      initial="initial"
      animate="animate"
      style={{
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <motion.div variants={animationVariant} transition={transition(0)}>
        {type === 'golden' && (
          <Typography variant={isSmall ? 'h4' : 'h2'} color="brand01.100">
            <FormattedMessage id="golden_x.general.label" />
          </Typography>
        )}
        <Typography variant={isSmall ? 'h4' : 'h2'} mb={3}>
          <FormattedMessage id="leaderboard.modal.title" />
        </Typography>
      </motion.div>

      <Box height="100%">
        {type !== 'golden' && (
          <motion.div variants={animationVariant} transition={transition(1)}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="leaderboard tabs"
              variant="fullWidth"
            >
              <Tab
                label={
                  <FormattedMessage id="leaderboard.modal.tab.last_episode.title" />
                }
              />
              <Tab
                label={
                  <FormattedMessage id="leaderboard.modal.tab.general.title" />
                }
              />
            </Tabs>
          </motion.div>
        )}
        {isFetchingLeaderboard || isFetchingUserLeaderboard ? (
          <LoadingState />
        ) : (
          <motion.div
            variants={animationVariant}
            transition={transition(2)}
            style={{ height: '100%' }}
          >
            {finalLeaderboard &&
            finalLeaderboard.length > 0 &&
            finalLeaderboard[0].score > 0 ? (
              <Leaderboard
                leaderboard={finalLeaderboard}
                userLeaderboard={
                  finalUserLeaderboard &&
                  currentUser &&
                  currentUser.position > POSITION_SPLIT
                    ? finalUserLeaderboard
                    : undefined
                }
              />
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                textAlign="center"
              >
                <Typography>
                  <FormattedMessage id="leaderboard.modal.no_data.label" />
                </Typography>
              </Box>
            )}
          </motion.div>
        )}
      </Box>
    </motion.div>
  );
};

export default LeaderboardContent;
