import { Box, Stack, Typography } from '@mui/material';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { formatDistance } from 'utils/map.utils';

import { BreakDownItem } from 'types/breakdown.types';

interface Props {
  breakdown: BreakDownItem;
  divider?: boolean;
}

const EpisodeBreakdown: React.FC<Props> = ({ breakdown, divider }) => {
  const { userPin, userPartner, endPointName } = breakdown;

  return (
    <Stack width="100%" gap={1}>
      <Box
        pb={1}
        borderBottom={divider ? 1 : 0}
        borderColor="neutral01.300"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="paragraph2">
          {userPin ? (
            <>
              {formatDistance(userPin.distanceInMeters)}{' '}
              <Typography
                color="neutral01.500"
                variant="paragraph2"
                component="span"
              >
                <FormattedMessage id="score.breakdown.distance.text" />
              </Typography>{' '}
              {endPointName}
            </>
          ) : (
            <FormattedMessage id="score.breakdown.distance.empty.text" />
          )}
        </Typography>
        <Box flexShrink={0}>
          <Typography variant="h5">
            + {userPin ? <FormattedNumber value={userPin.pointsScored} /> : 0}
          </Typography>
        </Box>
      </Box>
      <Box
        pb={1}
        borderBottom={divider ? 1 : 0}
        borderColor="neutral01.300"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="paragraph2">
          {userPartner ? (
            <>
              <FormattedMessage id="score.breakdown.participant.text" />{' '}
              {userPartner.participantFirstName}
            </>
          ) : (
            <FormattedMessage id="score.breakdown.participant.empty.text" />
          )}
        </Typography>
        <Box flexShrink={0}>
          <Typography variant="h5">
            +{' '}
            {userPartner ? (
              <FormattedNumber value={userPartner.pointsScored} />
            ) : (
              0
            )}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default EpisodeBreakdown;
