import { isPast, parseISO } from 'date-fns';

import { Episode } from 'types/episode.types';

export function episodeIsLocked(episode: Episode) {
  if (episode.votesLockedDate && isPast(parseISO(episode.votesLockedDate))) {
    return true;
  }

  return false;
}

export function episodeIsOpen(episode: Episode) {
  if (episode.votesOpenDate && isPast(parseISO(episode.votesOpenDate))) {
    return true;
  }

  return false;
}
