import { Store } from 'redux';

import config from 'config/env';

import { checkForLogin } from './auth';

export default async function initializeApp(store: Store) {
  try {
    if (config.AUTH_TYPE !== 'cookie') {
      checkForLogin(store);
    }
  } catch {}
}
