import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useAuth } from 'hooks';
import { CacheTime, StaleTime } from 'queries/constants';
import { QueryKeys } from 'queries/QueryKeys';
import { apiService } from 'services';
import { useFeatureFlags } from 'services/feature-flags';
import { Episode } from 'types/episode.types';
import { Feature } from 'types/features.types';

const getFinalGoldenX = async () => {
  const { data } = await apiService.getFinalGoldenX();

  return data;
};

export const useFinalGoldenX = (enabled = true) => {
  const queryClient = useQueryClient();
  const { hasFeature } = useFeatureFlags();
  const { userId } = useAuth();

  const { data, isLoading } = useQuery({
    queryKey: QueryKeys.goldenX.final(),
    queryFn: async () => {
      const finalX = await getFinalGoldenX();

      // Find the latest episode to update golden x end
      if (finalX.votesLockedDate) {
        const episodes = queryClient.getQueryData<Array<Episode>>(
          QueryKeys.episode.all(),
        );

        // Find episode with isLastEpisode and set the goldenXEndDate
        episodes?.map((episode) => {
          if (episode.isLastEpisode) {
            episode.goldenXEndDate = finalX.votesLockedDate;
          }
        });

        queryClient.setQueryData(QueryKeys.episode.all(), episodes);
      }

      return finalX;
    },
    enabled: !!userId?.length && enabled && hasFeature(Feature.GoldenX),
    gcTime: CacheTime.FOREVER,
    staleTime: StaleTime.MINUTE,
    meta: { persist: true },
  });

  return {
    data,
    isLoading,
  };
};
