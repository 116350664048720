import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import _isEqual from 'lodash/isEqual';
import { usePostHog } from 'posthog-js/react';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';

import config from 'config/env';

import { isProd } from 'utils/env.utils';

import { gtmParams } from 'services/analytics';
import { getTrackingEnabled } from 'store/settings/settings.selectors';
import { EventName, PageView } from 'types/analytics.types';

import { useAuth } from './auth';

interface Params {
  eventName: EventName | string;
  data?: { [key: string]: unknown };
  once?: boolean;
}

interface PageViewParams {
  path: PageView | string;
  data?: { [key: string]: unknown };
  once?: boolean;
}

const ANALYTICS_ENABLED = !!gtmParams.id.length;

export const useAnalytics = () => {
  const posthog = usePostHog();
  const sendDataToGTM = useGTMDispatch();
  const { isLoggedIn, userId } = useAuth();
  const hasBeenCalled = useRef(false);
  const prevPageData = useRef<object | undefined>();
  const prevEventData = useRef<object | undefined>();

  const isTrackingEnabled =
    useSelector(getTrackingEnabled) && ANALYTICS_ENABLED;

  const trackPageView = useCallback(
    ({ path, data = {}, once = true }: PageViewParams) => {
      if (!isTrackingEnabled) return;

      const trackData = {
        event: 'PageView',
        eventCategory: 'screenview',
        eventAction: 'speel-mee',
        eventLabel: config.PROGRAM.toLowerCase().replaceAll(' ', '-'),
        userStatus: isLoggedIn ? 'loggedin' : 'anonymous',
        screen: path,
        page: {
          id: '',
          title: config.PROGRAM,
          pubDate: '',
          brand: config.BRAND,
          language: 'nl',
          type: 'Interactive',
          subType: 'game',
          path,
        },
        user: userId
          ? {
              account_id: userId,
            }
          : undefined,
        program: config.PROGRAM,
        ...data,
      };

      if (
        (once && !hasBeenCalled.current) ||
        !once ||
        (prevPageData.current && !_isEqual(trackData, prevPageData.current))
      ) {
        hasBeenCalled.current = true;
        prevPageData.current = trackData;

        if (!isProd()) {
          // eslint-disable-next-line no-console
          console.log(`Tracking page: ${path}`);
        }

        sendDataToGTM({ ...trackData });

        posthog.capture(path, trackData);
      }
    },
    [isTrackingEnabled, isLoggedIn, userId, sendDataToGTM, posthog],
  );

  const trackEvent = useCallback(
    ({ eventName, data = {} }: Params) => {
      if (!isTrackingEnabled) return;

      const trackData = {
        event: 'Interactive Event',
        eventAction: eventName,
        eventCategory: eventName.includes('vtm-go') ? 'vtm-go-clicks' : 'game',
        eventLabel: config.PROGRAM.toLowerCase().replaceAll(' ', '-'),
        user: userId
          ? {
              account_id: userId,
            }
          : undefined,
        ...data,
      };

      if (
        prevEventData.current === undefined ||
        !_isEqual(trackData, prevEventData.current)
      ) {
        prevEventData.current = trackData;

        if (!isProd()) {
          // eslint-disable-next-line no-console
          console.log(`Tracking event: ${eventName}`);
        }

        sendDataToGTM({ ...trackData });
        posthog.capture(eventName, trackData);
      }
    },
    [isTrackingEnabled, userId, sendDataToGTM, posthog],
  );

  return {
    trackEvent,
    trackPageView,
  };
};
