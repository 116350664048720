import { Button, Stack, Typography } from '@mui/material';
import { motion, Transition, Variants } from 'motion/react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { navigationActions } from 'store/navigation/navigation.slice';
import { MiniGames } from 'types/game.types';

import { GameProgress } from 'components/@games';

interface Props {
  quiz: MiniGames['quiz'];
}

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const transition = (index: number): Transition => {
  return { delay: 0.5 + index * 0.3, duration: 0.7, ease: 'easeOut' };
};

const Results = ({ quiz }: Props) => {
  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(navigationActions.MODAL_CLOSE());
  };

  return (
    <motion.div initial="initial" animate="animate">
      <Stack
        flex={1}
        justifyContent={{ xs: 'space-between', md: 'flex-start' }}
        alignItems="center"
      >
        <motion.div variants={animationVariant} transition={transition(0)}>
          <Typography width="100%" variant="h2">
            <FormattedMessage id="games.modal.quiz.title" />
          </Typography>
        </motion.div>
        <motion.div variants={animationVariant} transition={transition(1)}>
          <Stack
            alignItems="center"
            justifyContent="center"
            maxWidth={448}
            flex={1}
          >
            <Typography variant="h4" color="primary.main">
              <FormattedMessage id="game.location.guess.correct.title" />
            </Typography>
            <Typography textAlign="center" my={2}>
              <FormattedMessage id="quiz.results.description" />
            </Typography>
            <GameProgress
              size={27}
              items={quiz.map((item) => item.userAnswer)}
            />
            <Button
              sx={{ display: { xs: 'none', md: 'flex' }, mt: 3 }}
              onClick={handleBack}
            >
              <FormattedMessage id="quiz.results.back.button" />
            </Button>
          </Stack>
        </motion.div>
        <motion.div variants={animationVariant} transition={transition(2)}>
          <Button
            sx={{ display: { xs: 'flex', md: 'none' }, mt: 3 }}
            onClick={handleBack}
          >
            <FormattedMessage id="quiz.results.back.button" />
          </Button>
        </motion.div>
      </Stack>
    </motion.div>
  );
};

export default Results;
