import { QueryKey } from '@tanstack/react-query';

export enum Keys {
  Episode = 'episode',
  Pin = 'pin',
  MiniGames = 'mini-games',
  Settings = 'settings',
  GameRules = 'game-rules',
  Participants = 'participants',
  Partner = 'partner',
  ScoreBreakDown = 'score-breakdown',
  Leaderboard = 'leaderboard',
  User = 'user',
  Heatmap = 'heat-map',
  GoldenX = 'golden-x',
}

export const QueryKeys = {
  user: {
    me: (): QueryKey => [Keys.User, 'me'],
  },
  episode: {
    all: (): QueryKey => [Keys.Episode],
    partner: (episodeId: string): QueryKey => [
      Keys.User,
      Keys.Partner,
      episodeId,
    ],
  },
  pin: {
    all: (): QueryKey => [Keys.User, Keys.Pin],
    byEpisode: (id: string): QueryKey => [Keys.User, Keys.Pin, id],
  },
  goldenX: {
    all: (): QueryKey => [Keys.User, Keys.GoldenX],
    final: (): QueryKey => [Keys.GoldenX, 'final'],
    tips: (episodeId: string): QueryKey => [
      Keys.User,
      Keys.GoldenX,
      'tips',
      episodeId,
    ],
    scoreBreakDown: (): QueryKey => [
      Keys.User,
      Keys.GoldenX,
      Keys.ScoreBreakDown,
    ],
  },
  heatmap: {
    all: (): QueryKey => [Keys.Heatmap],
    byEpisode: (id: string, gold: boolean, production: boolean): QueryKey => [
      Keys.Heatmap,
      id,
      gold ? 'golden' : 'episode',
      production ? 'production' : 'staging',
    ],
  },
  miniGames: {
    all: (): QueryKey => [Keys.User, Keys.MiniGames],
    byEpisode: (id: string) => [Keys.User, Keys.MiniGames, id],
  },
  settings: {
    gameRules: (): QueryKey => [Keys.Settings, Keys.GameRules],
  },
  participants: (): QueryKey => [Keys.Participants],
  scoreBreakDown: (): QueryKey => [Keys.User, Keys.ScoreBreakDown],
  leaderboard: {
    all: (): QueryKey => [Keys.Leaderboard],
    general: (type: string): QueryKey => [Keys.Leaderboard, type],
    user: (type: string): QueryKey => [Keys.Leaderboard, Keys.User, type],
  },
};
