import { Easing, Tween, update } from '@tweenjs/tween.js';
import { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useEpisodes, useUserGoldenPin, useUserPin } from 'queries';
import {
  getPlacingPinEpisodeId,
  getPlacingPinType,
} from 'store/navigation/navigation.selectors';
import {
  navigationActions,
  PinPlacementType,
} from 'store/navigation/navigation.slice';

import { useMap } from 'components/@map';

import { useEpisodeVotesClosed } from './useEpisodeVotesClosed';
import { useGoldenXStatus } from './useGoldenXStatus';

export const usePinPlacement = () => {
  const { nextEpisode, episodes } = useEpisodes();

  const pinType = useSelector(getPlacingPinType);
  const episodeId = useSelector(getPlacingPinEpisodeId);

  const activeEpisode = useMemo(
    () => episodes?.find((e) => e.id === episodeId),
    [episodeId, episodes],
  );

  const { setPin, pinSetError } = useUserPin(episodeId);
  const { setGoldenPin, goldenPinSetError } = useUserGoldenPin(episodeId);

  const isEpisodeLocked = !!useEpisodeVotesClosed(activeEpisode);
  const goldInfo = useGoldenXStatus(episodeId);

  const isLocked = pinType === 'golden' ? !goldInfo.open : isEpisodeLocked;

  const [map] = useMap();
  const didStartLockAnim = useRef(false);

  const dispatch = useDispatch();

  const animateLockMap = useCallback(
    (position: google.maps.LatLngLiteral, onFinish?: () => void) => {
      if (!map) return;

      const cameraOptions: google.maps.CameraOptions = {
        tilt: 0,
        heading: 0,
        zoom: map.getZoom() ?? 5,
        center: {
          lat: map.getCenter()?.lat() ?? 0,
          lng: map.getCenter()?.lng() ?? 0,
        },
      };

      let frame = requestAnimationFrame(animate);

      function animate(time: number) {
        frame = requestAnimationFrame(animate);
        update(time);
      }

      new Tween(cameraOptions) // Create a new tween that modifies 'cameraOptions'.
        .to({ tilt: 65, heading: 360, zoom: 5, center: position }, 10000)
        .easing(Easing.Quartic.InOut) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
          map.moveCamera(cameraOptions);
        })
        .onComplete(() => {
          onFinish?.();
          didStartLockAnim.current = false;
          cancelAnimationFrame(frame);
        })
        .start();
    },
    [map],
  );

  const animateUnlockMap = useCallback(() => {
    if (!map) return;

    const cameraOptions = {
      tilt: map.getTilt(),
      heading: map.getHeading(),
      zoom: map.getZoom(),
    };

    let frame = requestAnimationFrame(animate);

    function animate(time: number) {
      frame = requestAnimationFrame(animate);
      update(time);
    }

    new Tween(cameraOptions) // Create a new tween that modifies 'cameraOptions'.
      .to({ tilt: 0, heading: 0 }, 2000)
      .easing(Easing.Quartic.InOut) // Use an easing function to make the animation smooth.
      .onUpdate(() => {
        map.moveCamera(cameraOptions);
      })
      .onComplete(() => {
        cancelAnimationFrame(frame);
      })
      .start();
  }, [map]);

  const startPinPlacement = useCallback(
    (type: PinPlacementType = 'episode', pinEpisodeId?: string) => {
      map?.setOptions({
        draggableCursor: 'pointer',
      });

      dispatch(
        navigationActions.START_PIN_PLACEMENT({
          type,
          episodeId: pinEpisodeId || nextEpisode?.id || '',
        }),
      );
    },
    [dispatch, map, nextEpisode?.id],
  );

  const lockPinPlacement = useCallback(
    (position: google.maps.LatLngLiteral, onFinish?: () => void) => {
      map?.setOptions({
        draggableCursor: '',
        gestureHandling: 'none',
        keyboardShortcuts: false,
      });

      dispatch(navigationActions.LOCK_PIN_PLACEMENT());

      if (pinType === 'golden') {
        setGoldenPin(
          { latitude: position.lat, longitude: position.lng },
          {
            onSuccess: () => {
              animateLockMap(position, onFinish);
            },
            onError: () => {
              onFinish?.();
            },
          },
        );
      } else {
        setPin(
          { latitude: position.lat, longitude: position.lng },
          {
            onSuccess: () => {
              animateLockMap(position, onFinish);
            },
            onError: () => {
              onFinish?.();
            },
          },
        );
      }
    },
    [map, dispatch, pinType, setGoldenPin, animateLockMap, setPin],
  );

  const stopPinPlacement = useCallback(() => {
    map?.setOptions({
      draggableCursor: '',
      gestureHandling: 'greedy',
      keyboardShortcuts: true,
    });
    animateUnlockMap();
    dispatch(navigationActions.STOP_PIN_PLACEMENT());
  }, [dispatch, map, animateUnlockMap]);

  return {
    start: startPinPlacement,
    lock: lockPinPlacement,
    stop: stopPinPlacement,
    type: pinType,
    error: pinType === 'golden' ? !!goldenPinSetError : !!pinSetError,
    isLocked,
    isLockedApi:
      pinType === 'golden'
        ? goldenPinSetError?.response?.data['errors'] ===
          'episode.golden_x_votes_locked'
        : pinSetError?.response?.data['errors'] === 'episode.votes_locked',
  };
};
